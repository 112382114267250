<template>
    <page title="Lançamentos Futuros">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'notas_fiscais'}">Notas Fiscais</v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-slide-group v-if="lancamentosSomadosPorData.length > 0" :value="dataSelecionada" mandatory class="mt-1 mb-n1">
            <v-slide-item class="" v-slot:default="{ active, toggle }">
                <v-card class="py-1 px-4 mr-4" outlined
                        :color="active ? 'primary white--text' : ''"
                        @click="dataChanged(0, toggle)" >
                    <span  class="mb-n2 d-block">TODOS</span>
                    <span class="caption">{{valorTotal | formatNumber}}</span>
                </v-card>
            </v-slide-item>
            <v-slide-item class=""
                          v-for="(lancamento, index) in lancamentosSomadosPorData" :key="index"
                          v-slot:default="{ active, toggle }">
                <v-card class="py-1 px-4 mr-4" outlined
                        :color="active ? 'primary white--text' : ''"
                        @click="dataChanged(index + 1, toggle)" >
                    <span  class="mb-n2 d-block">{{lancamento.dataPagamento | moment("DD/MM/YYYY")}}</span>
                    <span class="caption">{{lancamento.valorTotal | formatNumber}}</span>
                </v-card>
            </v-slide-item>
        </v-slide-group>

        <div class="mt-2" v-if="totalCount > 0">
            <span class="subtitle-2 font-weight-regular">{{totalCount}} resultados encontrados</span>
        </div>

        <div class="text-center" v-if="notasFicais">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>
        <v-card class="mt-3">
            <v-list two-line v-if="notasFicais && notasFicais.length > 0">
                <template v-for="(notaFiscal, index) in notasFicais">
                    <v-list-item :to="{ name: 'nota_fiscal_view', params: { id: parseInt(notaFiscal.id) }}" >
                        <v-list-item-content>
                            <v-list-item-title><span>{{notaFiscal.numero}}</span><span class="font-weight-light"> - {{notaFiscal.discriminacao}}</span></v-list-item-title>
                            <v-list-item-subtitle class="d-flex">
                                Valor Nota <b class="black--text ml-1">{{notaFiscal.valorServicos | formatNumber}}</b>
                                <template v-if="notaFiscal.dataPagamento">
                                    <div class="ml-3">
                                        Valor Pago <b class="green--text">{{notaFiscal.valorPago | formatNumber}}</b>
                                    </div>
                                    <div class="ml-3">
                                        Descontado <b class="red--text">{{notaFiscal.descontoPagamento}}%</b>
                                    </div>
                                </template>
                                <v-chip v-if="notaFiscal.lembretes.length > 0" x-small label color="yellow" text-color="" class="ml-2 mr-1 font-weight-medium px-2">
                                    <span>{{notaFiscal.lembretes.length}} {{notaFiscal.lembretes.length > 1 ? "LEMBRETES" : "LEMBRETE"}}</span>
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>
                                Emissão: <b>{{notaFiscal.createdAt | moment("DD MMM YYYY HH:mm")}}</b>
                            </v-list-item-action-text>
                            <v-list-item-action-text>
                                Pagamento: <b>{{notaFiscal.dataPagamento | moment("DD MMM YYYY")}}</b>
                            </v-list-item-action-text>
                        </v-list-item-action>
                        <v-list-item-action>
                            <v-list-item-action-text>
                                <v-btn icon color="indigo" @click.prevent="downloadDanfe(notaFiscal.id)" :disabled="notaFiscal.arquivos.length === 0">
                                    <v-icon>mdi-file-download-outline</v-icon>
                                </v-btn>
                            </v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider ></v-divider>
                </template>
            </v-list>
            <div v-else-if="loading === false && (notasFicais === null || notasFicais.length === 0)" class="text-center pa-6">
                <h1 class="display-3 mb-4">Ops!</h1>
                <span class="headline font-weight-light">Nenhum resultado encontrado!</span>
            </div>
            <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
            <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="secondary" />
        </v-card>
        <div class="text-center mt-3" v-if="notasFicais">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>
    </page>
</template>

<script>
// @ts-ignore
import page from '../../../components/Page';
import gql from "graphql-tag";
export default {
    name: "LancamentosFuturos",
    components: {
        page,
    },
    data() {
        return {
            offset: 20,
            page: 1,
            totalCount: 0,
            lancamentosSomadosPorData: [],
            notasFicais: [],
            dataSelecionada: 0,
            loading: true,
        }
    },
    watch: {
        $route(to, from) {
            if(to.query.page === undefined){
                this.$router.push({query:{page: '1'}});
                return;
            }
            this.page = parseInt(to.query.page);
            this.dataSelecionada = parseInt(to.query.date);
            if(to.query.page === from.query.page && to.query.date === from.query.date){
                return
            }

            if(this.lancamentosSomadosPorData.length === 0){
                this.getLancamentosSomadosPorData().then(()=>{
                    this.getList(this.dataSelecionada)
                })
            }else{
                this.getList(this.dataSelecionada)
            }
        },
    },
    computed: {
        valorTotal(){
            let total = 0;
            this.lancamentosSomadosPorData.forEach(lancamento => {
                total += lancamento.valorTotal;
            })
            return total;
        }
    },
    methods: {
        dataChanged(index, toggle){
            toggle()
            this.page = 1
            this.$router.push({query:{page:this.page, date: index}});
        },
        pageChanged(value){
            this.$router.push({query:{page:this.page, date: this.dataSelecionada}});
        },
        getLancamentosSomadosPorData(){
            return new Promise((resolve, reject) => {
                this.$apollo.query({
                    query: gql`query {
                    notasFiscaisLancamentosFuturosTotais{
                        quantidade
                        valorTotal
                        dataPagamento
                    }
                }`,
                }).then(result => {
                    this.lancamentosSomadosPorData = result.data.notasFiscaisLancamentosFuturosTotais;
                    resolve();
                })
            })
        },
        getList(index) {
            this.loading = true;
            let data = null;

            if(index > 0){
                data = new Date(this.lancamentosSomadosPorData[index - 1].dataPagamento).getTime()
            }

            this.$apollo.query({
                query: gql`query($data: LocalDate, $page: Int, $offset: Int) {
                        notasFiscaisLancamentosFuturos(data: $data, page: $page, offset: $offset){
                            totalCount
                            notasFiscais:itens{
                                id
                                numero
                                dataEmissao
                                dataPagamento
                                valorServicos
                                valorPago
                                descontoPagamento
                                discriminacao
                                createdAt
                                arquivos{
                                    id
                                }
                                lembretes{
                                    id
                                }
                            }
                        }
                    }`,
                variables: {
                    data,
                    page: this.page - 1,
                    offset: this.offset
                },
            }).then((result) => {
                this.notasFicais = result.data.notasFiscaisLancamentosFuturos.notasFiscais;
                this.totalCount = result.data.notasFiscaisLancamentosFuturos.totalCount;
                this.loading = false;
            });
        },
        downloadDanfe(notaFiscalId) {
            //this.downloadingDanfe = true;
            this.$axios.get('/notas_fiscais/' + notaFiscalId + '/download_danfe', {responseType: 'blob'}).then((response) => {
                const fileLink = document.createElement('a');
                fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                const fileName = response.headers['content-disposition'].split('filename=')[1];
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                //this.downloadingDanfe = false;
            }).catch(() => {
                //this.downloadingDanfe = false;
                this.$dialog.error({
                    text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                    title: 'Ops',
                });

            });
        },
    },
    mounted() {
        document.title = "Lançamentos Futuros - JF Assessoria Rural"
        if(!this.$route.query.page || !this.$route.query.date){
            this.$router.replace({query:{page:this.page, date:this.dataSelecionada}});
            return;
        }else{
            this.page = parseInt(this.$route.query.page);
            this.dataSelecionada = parseInt(this.$route.query.date);
        }
        this.getLancamentosSomadosPorData().then(() => {
            this.getList(this.dataSelecionada);
        })
    },
}
</script>

<style scoped>

</style>